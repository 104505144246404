import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import ProjectIcon from '../../images/projet.jpg';
import B1 from '../../images/new/pro_fct1.jpg';
import B2 from '../../images/new/pro_fct2.jpg';
import B3 from '../../images/new/pro_fct3.jpg';

const ProjectPage = () => (
  <Layout>
    <Feature
      previous="/features/ideabox"
      next="/features/intra"
      color={theme.palette.green.main}
      title="Lab"
      icon={ProjectIcon}
      subtitle="N'arrêtez pas vos démarches au stade d'idéation, passez à la validation."
      description="Dès qu'une idée semble prometteuse, valider sa pertinence et sa faisabilité en interrogeant les
      personnes concernées dans votre entreprise. Les fausses bonnes idées sont ainsi écartées avant d'y
      avoir investi du temps et de l'argent. Les meilleures sont validées et une équipe commence à se
      former."
      subSections={[
        'Valider vos futurs projets d’innovation',
        'Identifier les équipes intrapreneuriales',
        'Écarter les fausses bonnes idées',
      ]}
      sections={[
        {
          title: 'Validation des idées',
          description:
            "Le responsable de l'étude lance différentes étapes (sondages, appels à contribution) et valide ainsi l’idée avec tous ses collaborateurs.",
          image: B1,
        },
        {
          title: "Communication sur l'étude",
          description:
            'Vos collaborateurs suivent facilement l’ensemble des avancées des études en consultant leur fil d’actualité.',
          image: B2,
        },
        {
          title: 'Formation d’une équipe',
          description:
            'Les personnes les plus compétentes travaillent facilement ensemble via une messagerie dédiée et un dépôt de fichiers.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default ProjectPage;
